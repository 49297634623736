// import { GENDER } from "../index";

export const WOMEN_STYLES = [
    // Bras
    {
        label: 'Wire-Free Bras',
        value: 'Wire-Free_Bras'
    },
    {
        label: 'Sports Bras',
        value: 'Sports_Bras'
    },
    {
        label: 'T-Shirt Bras',
        value: 'T-Shirt_Bras'
    },
    {
        label: 'Minimizer Bras',
        value: 'Minimizer_Bras'
    },
    {
        label: 'Underwire Bras',
        value: 'Underwire_Bras'
    },
    {
        label: 'Strapless Bras',
        value: 'Strapless_Bras'
    },
    {
        label: 'Bralettes',
        value: 'Bralettes'
    },
    {
        label: 'Front-Close Bras',
        value: 'Front-Close_Bras'
    },
    {
        label: 'Push-Up Bras',
        value: 'Push-Up_Bras'
    },
    {
        label: 'Racerback Bras',
        value: 'Racerback_Bras'
    },
    {
        label: 'Nursing & Maternity',
        value: 'Nursing_&_Maternity'
    },
    {
        label: 'Mastectomy',
        value: 'Mastectomy'
    },
    {
        label: 'Balconette',
        value: 'Balconette'
    },
    {
        label: 'Plunge',
        value: 'Plunge'
    },
    {
        label: 'Lightly Lined Bras',
        value: 'Lightly_Lined_Bras'
    },
    // Panties
    {
        label: 'Thongs',
        value: 'Thongs'
    },
    {
        label: 'Hi-Cut Briefs',
        value: 'Hi-Cut_Briefs'
    },
    {
        label: 'Briefs',
        value: 'Briefs'
    },
    {
        label: 'Bikinis',
        value: 'Bikinis'
    },
    {
        label: 'Crotchless',
        value: 'Crotchless'
    },
    {
        label: 'Boyshorts',
        value: 'Boyshorts'
    },
    {
        label: 'Cheeky',
        value: 'Cheeky'
    },
    {
        label: 'Hipster',
        value: 'Hipster'
    },
    {
        label: 'G-String',
        value: 'G-String'
    },
    {
        label: 'Shaping & Control',
        value: 'Shaping_&_Control'
    },
    {
        label: 'Seamless',
        value: 'Seamless'
    },
    {
        label: 'Sheer',
        value: 'Sheer'
    },
    {
        label: 'Multi-Packs',
        value: 'Multi-Packs'
    },
    {
        label: 'Leak Proof',
        value: 'Leak_Proof'
    },
    {
        label: 'Plus Size',
        value: 'Plus_Size'
    },
    // Shapewear
    {
        label: 'Shaping Bodysuits',
        value: 'Shaping_Bodysuits'
    },
    {
        label: 'Shaping Panties',
        value: 'Shaping_Panties'
    },
    {
        label: 'Slimming Camisoles',
        value: 'Slimming_Camisoles'
    },
    {
        label: 'Mid-Thigh Shapers',
        value: 'Mid-Thigh_Shapers'
    },
    {
        label: 'Shaping Leggings',
        value: 'Shaping_Leggings'
    },
    {
        label: 'High-Waisted Panties',
        value: 'High-Waisted_Panties'
    },
    {
        label: 'Longline Bras',
        value: 'Longline_Bras'
    },
    {
        label: 'Bridal Shapewear',
        value: 'Bridal_Shapewear'
    },
    {
        label: 'Back-Smoothing Bras',
        value: 'Back-Smoothing_Bras'
    },
    {
        label: 'Backless',
        value: 'Backless'
    }
]
