import React from "react";
import Logo from "../assets/images/circlelogo3.jpg";

const header = () => {
    return (
        <>
            <div className="flex justify-between">
                <div className="flex justify-start items-end border-border-dark inline-block">
                    <a href="/">
                        <img className="pb-1" src={Logo} alt="" />
                    </a>
                </div>
                <div className="flex items-center pt-5">
                    <ul className="flex flex-wrap divide-x">
                        <li className="px-2"><a href="/women" className="text-indigo-800">Women</a></li>
                        <li className="px-2"><a href="/men" className="text-indigo-800">Men</a></li>
                    </ul>
                </div>
            </div>
            <div className="bg-border-dark text-white pl-2">
                <p>because it matters what's underneath™</p>
            </div>
        </>
    )
}

export default header;