export const MEN_BRANDS = {
    LINE1: [
        {
            label: '2UNDR',
            value: '2UNDR'
        },
        {
            label: '2xist',
            value: '2xist'
        },
        {
            label: 'Addicted',
            value: 'Addicted'
        },
        {
            label: 'Adidas',
            value: 'Adidas'
        },
        {
            label: 'Agacio',
            value: 'Agacio'
        },
        {
            label: 'Andrew Christian',
            value: 'Andrew_Christian'
        },
        {
            label: 'Balenciaga',
            value: 'Balenciaga'
        },
        {
            label: 'Calvin Klein',
            value: 'Calvin_Klein'
        },
        {
            label: 'Candyman',
            value: 'Candyman'
        },
        {
            label: 'C-IN2',
            value: 'C-IN2'
        },
        {
            label: 'Clever',
            value: 'Clever'
        },
        {
            label: 'Cocksox',
            value: 'Cocksox'
        },
        {
            label: 'Cosabella',
            value: 'Cosabella'
        },
        {
            label: 'CR7',
            value: 'CR7'
        },
        {
            label: 'Daniel Alexander',
            value: 'Daniel_Alexander'
        },
        {
            label: 'Diesel',
            value: 'Diesel'
        },
        {
            label: 'Dolce + Gabbana',
            value: 'Dolce + Gabbana'
        },
        {
            label: 'Doreanse',
            value: 'Doreanse'
        },
        {
            label: 'Emporio Arman',
            value: 'Emporio_Arman'
        },
        {
            label: 'Ergowear',
            value: 'Ergowear'
        },
        {
            label: 'Falke',
            value: 'Falke'
        },
        {
            label: 'Fruit of the Loom',
            value: 'Fruit_of_the_Loom'
        },
        {
            label: 'Go Softwear',
            value: 'Go_Softwear'
        },
        {
            label: 'Gregg Homme',
            value: 'Gregg_Homme'
        },
        {
            label: 'Hanes',
            value: 'Hanes'
        },
        {
            label: 'Hanro',
            value: 'Hanro'
        },
        {
            label: 'HOM USA',
            value: 'HOM_USA'
        },
        {
            label: 'Hugo Boss',
            value: 'Hugo_Boss'
        },
        {
            label: 'Intyman',
            value: 'Intyman'
        },
        {
            label: 'Jack Adams',
            value: 'Jack_Adams'
        },
        {
            label: 'Jockey',
            value: 'Jockey'
        },
        {
            label: 'Joe Snyder',
            value: 'Joe_Snyder'
        },
        {
            label: 'JOR',
            value: 'JOR'
        },
        {
            label: 'Mack Weldon',
            value: 'Mack_Weldon'
        }
    ],
    LINE2: [
        {
            label: 'Magic Silk',
            value: 'Magic_Silk'
        },
        {
            label: 'Male Basics',
            value: 'Male_Basics'
        },
        {
            label: 'Male Power',
            value: 'Male_Power'
        },
        {
            label: 'Michael Kors',
            value: 'Michael_Kors'
        },
        {
            label: 'MOB',
            value: 'MOB'
        },
        {
            label: 'Mundo Unico',
            value: 'Mundo_Unico'
        },
        {
            label: 'Munsingwear',
            value: 'Munsingwear'
        },
        {
            label: 'Obviously',
            value: 'Obviously'
        },
        {
            label: 'Papi',
            value: 'Papi'
        },
        {
            label: 'Paul Smith',
            value: 'Paul_Smith'
        },
        {
            label: 'Perry Ellis',
            value: 'Perry_Ellis'
        },
        {
            label: 'Petit Q',
            value: 'Petit_Q'
        },
        {
            label: 'Pikante',
            value: 'Pikante'
        },
        {
            label: 'Polo Ralph Lauren',
            value: 'Polo_Ralph_Lauren'
        },
        {
            label: 'PPV',
            value: 'PPV'
        },
        {
            label: 'Tom Ford',
            value: 'Tom_Ford'
        },
        {
            label: 'Tommy Hilfiger',
            value: 'Tommy_Hilfiger'
        },
        {
            label: 'UGG',
            value: 'UGG'
        },
        {
            label: 'Under Armour',
            value: 'Under_Armour'
        },
        {
            label: 'Pump!',
            value: 'Pump!'
        },
        {
            label: 'Saxx',
            value: 'Saxx'
        },
        {
            label: 'Supawear',
            value: 'Supawear'
        },
        {
            label: 'Versace',
            value: 'Versace'
        },
        {
            label: 'Xtremen',
            value: 'Xtremen'
        },
        {
            label: 'Zegna',
            value: 'Zegna'
        }
    ],
}

