// import { GENDER } from "../index";

export const MEN_STYLES = [
    {
        label: 'Bikinis',
        value: 'Bikinis'
    },
    {
        label: 'Briefs',
        value: 'Briefs'
    },
    {
        label: 'Boxer Briefs',
        value: 'Boxer_Briefs'
    },
    {
        label: 'Boxer Shorts',
        value: 'Boxer_Shorts'
    },
    {
        label: 'Jockstraps',
        value: 'Jockstraps'
    },
    {
        label: 'Thongs',
        value: 'Thongs'
    },
    {
        label: 'Squarecut',
        value: 'Squarecut'
    },
    {
        label: 'T-Shirts',
        value: 'T-Shirts'
    },
    {
        label: 'Tank Tops',
        value: 'Tank_Tops'
    },
    {
        label: 'V-Neck',
        value: 'V-Neck'
    },
    {
        label: 'Crew Neck',
        value: 'Crew_Neck'
    },
    {
        label: 'Shapewear',
        value: 'Shapewear'
    },
    {
        label: 'Enhancement',
        value: 'Enhancement'
    }
]