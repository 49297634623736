import React from "react";

// import { BRAS_STYLES, PANTIES_STYLES, SHAPEWEAR_STYLES } from "../const/women.js";
// import { MEN_STYLES } from "../const/men.js"

const footer = () => {
    return (
        <>
            <div className="border-t-20 border-secondary">
                <div className="border-t-4 border-border-light mx-52 mt-3"></div>
                <p className="text-center pt-3">
                    <a href="/about" className="font-bold text-2xs text-indigo-800">About Us</a>
                </p>
                <p className="text-center pb-3">
                    <a href="/contact" className="font-bold text-2xs text-indigo-800">Contact Us</a>
                </p>
                <p className="text-xs text-center pb-4">Copyright @2010 Underneath.com.  All rights reserved.
                    <a href="/privacy" className="text-indigo-800">Privacy Policy</a>
                </p>
            </div>
        </>
    )
}

export default footer;