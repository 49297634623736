export const HEADER_STYLE_LOGO_CONTENT = {
    // Women's styles
    '1-Wire-Free_Bras': {
        cover: '',
        description: ''
    },
    '1-Sports_Bras': {
        cover: '',
        description: ''
    },
    '1-T-Shirt_Bras': {
        cover: '',
        description: ''
    },
    '1-Minimizer_Bras': {
        cover: '',
        description: ''
    },
    '1-Underwire_Bras': {
        cover: '',
        description: ''
    },
    '1-Strapless_Bras': {
        cover: '',
        description: ''
    },
    '1-Bralettes': {
        cover: '',
        description: ''
    },
    '1-Front-Close_Bras': {
        cover: '',
        description: ''
    },
    '1-Push-Up_Bras': {
        cover: '',
        description: ''
    },
    '1-Racerback_Bras': {
        cover: '',
        description: ''
    },
    '1-Nursing_&_Maternity': {
        cover: '',
        description: ''
    },
    '1-Mastectomy': {
        cover: '',
        description: ''
    },
    '1-Balconette': {
        cover: '',
        description: ''
    },
    '1-Plunge': {
        cover: '',
        description: ''
    },
    '1-Lightly_Lined_Bras': {
        cover: '',
        description: ''
    },
    '1-Thongs': {
        cover: '',
        description: ''
    },
    '1-Hi-Cut_Briefs': {
        cover: '',
        description: ''
    },
    '1-Briefs': {
        cover: '',
        description: ''
    },
    '1-Bikinis': {
        cover: '',
        description: ''
    },
    '1-Crotchless': {
        cover: '',
        description: ''
    },
    '1-Boyshorts': {
        cover: '',
        description: ''
    },
    '1-Cheeky': {
        cover: '',
        description: ''
    },
    '1-Hipster': {
        cover: '',
        description: ''
    },
    '1-G-String': {
        cover: '',
        description: ''
    },
    '1-Shaping_&_Control': {
        cover: '',
        description: ''
    },
    '1-Seamless': {
        cover: '',
        description: ''
    },
    '1-Sheer': {
        cover: '',
        description: ''
    },
    '1-Multi-Packs': {
        cover: '',
        description: ''
    },
    '1-Leak_Proof': {
        cover: '',
        description: ''
    },
    '1-Plus_Size': {
        cover: '',
        description: ''
    },
    '1-Shaping_Bodysuits': {
        cover: '',
        description: ''
    },
    '1-Shaping_Panties': {
        cover: '',
        description: ''
    },
    '1-Slimming_Camisoles': {
        cover: '',
        description: ''
    },
    '1-Mid-Thigh_Shapers': {
        cover: '',
        description: ''
    },
    '1-Shaping_Leggings': {
        cover: '',
        description: ''
    },
    '1-High-Waisted_Panties': {
        cover: '',
        description: ''
    },
    '1-Longline_Bras': {
        cover: '',
        description: ''
    },
    '1-Bridal_Shapewear': {
        cover: '',
        description: ''
    },
    '1-Back-Smoothing_Bras': {
        cover: '',
        description: ''
    },
    '1-Backless': {
        cover: '',
        description: ''
    },

    // Men's styles
    '2-Bikinis': {
        cover: '',
        description: ''
    },
    '2-Briefs': {
        cover: '',
        description: ''
    },
    '2-Boxer_Briefs': {
        cover: '',
        description: ''
    },
    '2-Boxer_Shorts': {
        cover: '',
        description: ''
    },
    '2-Jockstraps': {
        cover: '',
        description: ''
    },
    '2-Thongs': {
        cover: '',
        description: ''
    },
    '2-Squarecut': {
        cover: '',
        description: ''
    },
    '2-T-Shirts': {
        cover: '',
        description: ''
    },
    '2-Tank_Tops': {
        cover: '',
        description: ''
    },
    '2-V-Neck': {
        cover: '',
        description: ''
    },
    '2-Crew_Neck': {
        cover: '',
        description: ''
    },
    '2-Shapewear': {
        cover: '',
        description: ''
    },
    '2-Enhancement': {
        cover: '',
        description: ''
    }
}