import { MEN_BRANDS } from "./brand/men";
import { WOMEN_BRANDS } from "./brand/women";
import { MEN_STYLES } from "./style/men";
import { WOMEN_STYLES } from "./style/women";

import { HEADER_BRAND_LOGO_CONTENT } from "./HEADER_BRAND_CONTENT";
import { HEADER_STYLE_LOGO_CONTENT } from "./HEADER_STYLE_CONTENT";
const GENDER = {
    WOMEN: 1,
    MEN: 2
}

export {
    GENDER,
    MEN_BRANDS,
    WOMEN_BRANDS,
    MEN_STYLES,
    WOMEN_STYLES,
    HEADER_BRAND_LOGO_CONTENT,
    HEADER_STYLE_LOGO_CONTENT
}