import React from "react"
import "./assets/App.css"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import Header from "./components/header";
import Footer from "./components/footer";

import { HomePage, WomenPage, MenPage, AboutPage, ContactPage, ShopBrandPage, ShopStylePage } from "./pages";
function App() {
  return (
    <>
      <div className="container lg:max-w-6xl">
        <Header />
        <Router>
          <Switch>
            <Route path='/' exact>
              <HomePage />
            </Route>
            {/* <Route path='/shop/:category' exact>
              <ShopPage />
            </Route>
            <Route path='/shop/:style' exact>
              <ShopPage />
            </Route> */}
            <Route path='/shop/style/:gender/:style' exact>
              <ShopStylePage />
            </Route>
            <Route path='/shop/brand/:gender/:brand' exact>
              <ShopBrandPage />
            </Route>
            <Route path='/men' exact>
              <MenPage />
            </Route>
            <Route path='/women' exact>
              <WomenPage />
            </Route>
            <Route path='/about' exact>
              <AboutPage />
            </Route>
            <Route path='/contact' exact>
              <ContactPage />
            </Route>
          </Switch>
        </Router>
        <Footer />
      </div>
    </>
  )
}

export default App
