import React from "react";

const Pagination = ({ currentPage, totalPages, handlePage }) => {
    return (
        <>
            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                <button className="relative inline-flex items-center rounded-l-md p-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0" onClick={() => handlePage(currentPage - 1)} disabled={currentPage == 1}>
                    <span className="sr-only">Previous</span>
                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
                    </svg>
                </button>

                {currentPage >= 3 && <button className="relative hidden items-center px-3 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex" onClick={() => handlePage(1)}>{1}</button>}
                {/* {currentPage >= 4 && <button className="relative hidden items-center px-3 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex" onClick={() => handlePage(2)}>{2}</button>}
                {currentPage >= 5 && <button className="relative hidden items-center px-3 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex" onClick={() => handlePage(3)}>{3}</button>} */}

                {currentPage >= 4 && <span className="relative inline-flex items-center px-3 py-1 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</span>}
                {currentPage >= 2 && <button className="relative inline-flex items-center px-3 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0" onClick={() => handlePage(currentPage - 1)}>{currentPage - 1}</button>}
                <button className="relative z-10 inline-flex items-center bg-primary px-3 py-1 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={() => handlePage(currentPage)}>{currentPage}</button>
                {currentPage < totalPages && <button className="relative hidden items-center px-3 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex" onClick={() => handlePage(currentPage + 1)}>{currentPage + 1}</button>}

                {(totalPages >= 5 && currentPage < (totalPages - 4)) && <span className="relative inline-flex items-center px-3 py-1 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</span>}

                {/* {(totalPages >=7 && currentPage < totalPages-3) && <button className="relative hidden items-center px-3 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex" onClick={() => handlePage(totalPages - 2)}>{totalPages - 2}</button>}
                {(totalPages >=6 && currentPage < totalPages-2) && <button className="relative inline-flex items-center px-3 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0" onClick={() => handlePage(totalPages - 1)}>{totalPages - 1}</button>} */}
                {(totalPages >= 5 && currentPage < totalPages - 1) && <button className="relative inline-flex items-center px-3 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0" onClick={() => handlePage(totalPages)}>{totalPages}</button>}

                <button className="relative inline-flex items-center rounded-r-md p-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0" onClick={() => handlePage(currentPage + 1)} disabled={currentPage == totalPages}>
                    <span className="sr-only">Next</span>
                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                    </svg>
                </button>
            </nav>
        </>
    )
}

export default Pagination
