import React from "react";

const AboutPage = () => {
  return (
    <>
      <div className="text-center">
        <h1 className="text-xl text-indigo-800 font-bold pt-3">About Us</h1>
        <p className="text-xs px-28 py-4 text-border-light">
          Underneath is a shopping comparison website that helps visitors find the best deal on underwear and intimate apparel online. Visitors can see specific products from multiple retail online outlets to compare pricing. They can then be forwarded to the retailer they choose to buy the product from.
        </p>
      </div>
    </>
  )
}

export default AboutPage
